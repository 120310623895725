@font-face {
  font-family: Rubik-Light;
  src: url(./assets/fonts/Rubik-Light.ttf);
}
@font-face {
  font-family: Rubik-Medium;
  src: url(./assets/fonts/Rubik-Medium.ttf);
}
@font-face {
  font-family: Rubik-Bold;
  src: url(./assets/fonts/Rubik-Bold.ttf);
}

body, a, h1, h2, h3, h4, p, ol, ul, li, input[type=text] {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Rubik-Light;
  color: #231F20;
}

a {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

strong, .strong {
  font-weight: normal;
  font-family: Rubik-Medium;
}

h1 {
  font-family: Rubik-Bold;
  font-size: 4.5vh;
  margin: 0 0 2.5vh 0;
}
h2 {
  font-family: Rubik-medium;
  font-size: 3vh;
  margin: 2.5vh 0 1vh 0;
}
h3 {
  font-family: Rubik-medium;
  font-size: 3vh;
  text-align: center;
  margin: 5vh 0 5vh 0
}

#root {
  padding: 50px 0 0 0;
  overflow-x: hidden;
}

.header-logo {
  width: 100px;
  max-width: 160px;
  min-width: 100px;
  pointer-events: none;
  overflow: hidden;
  display: block;
}

.header {

  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  z-index: 1000;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
}

.home-link {
  position: relative;
  top: 50%;
  left: 0;
  display: block;
  float: left;
  transform: translateY(-50%);
  margin: 0 0 0 5vw;
}

.header.header-hide {
  transition: all 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transform: translateY(-50px)
}
.header.header-show {
  transition: all 0.4s cubic-bezier(0, .52, 0, 1);
  transform: translateY(0px)
}

.header .burger-container {
  position: relative;
  display: inline-block;
  float: right;
  height: 100%;
  width: 50px;
  top: 0;
  right: -12.5px;
  margin: 0 5vw 0 0;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.header .burger-container #burger {
  width: 25px;
  position: relative;
  display: block;
  margin: -15% auto;
  top: 50%;
}
.header .burger-container #burger .bar {
  width: 100%;
  height: 1px;
  display: block;
  position: relative;
  background: #000;
}
.header .burger-container #burger .bar.topBar {
  transform: translateY(0px) rotate(0deg);
}
.header .burger-container #burger .bar.middleBar {
  transform: translateY(5px) rotate(0deg);
}
.header .burger-container #burger .bar.bottomBar {
  transform: translateY(10px) rotate(0deg);
}

.header.hide .burger-container {
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
}
.header.hide .burger-container #burger .bar {
  transition: background 0.4s linear;
  transition-delay: 0s;
}
.header.hide .burger-container #burger .bar.topBar {
  transition: transform 0.4s cubic-bezier(0, .52, 0, 1) 0.5s,
  background 0.4s linear;
  transform: translateY(0px) rotate(0deg);
}
.header.hide .burger-container #burger .bar.middleBar {
  transition: transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.6s,
  background 0.4s linear;
  transform: translateY(5px) rotate(0deg);
}
.header.hide .burger-container #burger .bar.bottomBar {
  transition: transform 0.4s cubic-bezier(0, .52, 0, 1) 0.5s,
  background 0.4s linear;
  transform: translateY(10px) rotate(0deg);
}
.header.show .header-logo path {
 fill: #fff !important;
}
.header .header-logo path {
 transition: fill 0.5s cubic-bezier(0, .52, 0, 1);
}
.header.show .header-logo .header-logo-stroke {
 opacity:  0;
 transition: opacity 0.0s;
}
.header.hide .header-logo .header-logo-stroke {
 opacity:  1;
 transition: opacity 0.5s ease-in;
}
.header.show .burger-container {
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transform: rotate(90deg);
}
.header.show .burger-container #burger .bar {
  transition: background 0.4s linear;
  background: #fff;
}
.header.show .burger-container #burger .bar.topBar {
  transition: transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.5s,
  background 0.4s linear;
  transform: translateY(5px) rotate(45deg);
}
.header.show .burger-container #burger .bar.middleBar {
  transition: transform 0.4s cubic-bezier(0, .52, 0, 1) 0.4s,
  background 0.4s linear;
  transform: translateY(5px) rotate(0deg) scale(0, 1);
}
.header.show .burger-container #burger .bar.bottomBar {
  transition: transform 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99) 0.5s,
  background 0.4s linear;
  transform: translateY(3px) rotate(-45deg);
}
 
#nav-menu {
  width: 100%;
  height: 100vh;
  background-color: #231F20;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100vw);
  display: block;
  overflow: hidden;
  z-index: 0;
}
#nav-menu.hide {
  transition: transform .5s cubic-bezier(0, .52, 0, 1);
  transform: translateX(-100vw);
}
#nav-menu.show {
  transition: transform .5s cubic-bezier(0, .52, 0, 1);
  transform: translateX(0vw);
}




#nav-menu .nav-menu-items {
  margin: 15vh 0 0 0;
}
#nav-menu .nav-menu-items a {
  color: #fff;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 5vh 0 5vh 10vw;
  text-decoration: none;
  transition: color 1s ease-out;
  color: #fff;
}
#nav-menu .nav-menu-items a .large-text {
  font-family: "Rubik-Medium";
  text-transform: uppercase; 
  font-size: 6.5vw;
  line-height: 1em;
  float:left;
  clear:both;
}
#nav-menu .nav-menu-items a .small-text {
  float:left;
  clear:both;
  font-size: 3vw;
}
#nav-menu .nav-menu-items a:nth-child(1):hover  {
  transition: color .1s ease-out;
  color: #FCE8B6;
}
#nav-menu .nav-menu-items a:nth-child(2):hover  {
  transition: color .1s ease-out;
  color: #FFD0FA;
}
#nav-menu .nav-menu-items a:nth-child(3):hover  {
  transition: color .1s ease-out;
  color: #E4C3FF;
}
#nav-menu .nav-menu-items a:nth-child(4):hover  {
  transition: color .1s ease-out;
  color: #B5CFFF;
}
#nav-menu .nav-menu-footer a:hover  {
  transition: color .1s ease-out;
  color: #E4C3FF;
}
#nav-menu .nav-menu-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  display: block;
  width: 100vw;
  text-align: right;
}
#nav-menu .nav-menu-footer a {
  color: #fff;
  display: block;
  overflow: hidden;
  text-decoration: none;
  font-size: 3.5vw;
}
#nav-menu .nav-menu-footer-items {
  color: #fff;
  margin: 20px 40px 20px 20px;
}

.start-page-content {
  display:  flex;
  flex-direction:  column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 50px);
}

#start-page-background-img-1 {
  margin: 0 0 0 0;
  position:  absolute;
  bottom:  -10vw;
  left:  -40vw;
  width:  135vw;
  z-index: -1;
  transform:  scaleX(-1);
}
#start-page-background-img-2 {
  margin: 0 0 0 0;
  position:  absolute;
  top:  -8vh;
  right:  -47vw;
  width:  100vw;
  z-index: -1;
  transform:  scaleX(-1);
}

#start-page-text {
  margin-top: 28vh;
  font-family: Rubik-Medium;
  text-align: center;
  font-size: 8vw;
}

.scroll-down-button {
 margin: 2vh 0 5vh 0;
 display: inline-flex;
 flex-direction: column;
 align-items: center;
 text-transform: uppercase;
 align-self: center;
 font-size: 4vw;
}

.scroll-down-button:hover {
  cursor: pointer;
}
.scroll-down-button .arrow {
 margin: 2vh 0 0 0;
 width: 35px;
 height: 35px;
}

.scroll-down-button.animated .arrow {
 animation: scroll 1.5s ease-in-out infinite;
}

@keyframes scroll {
  40%, 100% {
    transform: translateY(0) scale(1);
  }
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(15px);
  }
  23% {
    transform: translateY(15px) scaleX(1.08) scaleY(0.92);
  }
  
}

.arrow {
 transition: transform 0.2s ease;
}


#customer-case-summaries {
  border: none;
  margin: 0 0 4vh -5vw;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: stretch;
} 

.customer-case-summary {
  border-top: 1px #F4F2EF solid;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  transition: background 0.2s linear;
  margin: 0 0;
  padding: 2vh 10vw 2vh 10vw;
  text-align:  left;
}

.customer-case-summary:nth-last-child(1) {
  border-bottom: 1px #F4F2EF solid;
}

.customer-case-summary:hover {
  border-color: rgb(244,231,255);
  background: linear-gradient(150deg, rgb(255,240,253) 0%, rgb(244,231,255) 100%);
  cursor: pointer;
}

.customer-case-summary.hide {
  flex: 1 1 auto;
}
.customer-case-summary.show {
  flex: 0 0 auto;
  border-color: rgb(244,231,255);
  background: linear-gradient(150deg, rgb(255,240,253) 0%, rgb(244,231,255) 100%);
  
}
.customer-case-summary .title-text {
  font-size: 5vw;
  font-family: 'Rubik-Light';
  margin: 2vh 0 2vh 0;
  border: none;
  display:  flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width:  100%;
}
.customer-case-summary.show .title-text {
  font-family: 'Rubik-Medium';
}


.customer-case-summary .title-text span {
  margin:  0;
  width:  100%;
}

.customer-case-summary.hide .title-text span {
  text-align: left;
}

.customer-case-summary .title-text .title-text-arrow {
  order:  1;
  margin:  0 0 0 5vw;
  display: flex;
  justify-content: center;
}
.customer-case-summary .arrow {
  margin:  0 0 0 0;
}

.customer-case-summary.show .title-text-arrow {

}

.customer-case-summary .body-text-container {
  overflow: hidden;
}
.customer-case-summary.hide .body-text-container {
  height: 0;
}

.customer-case-summary .body-text-container p {
  padding: 0 0 2vw 0;
  margin: 0;
}

.customer-logo-ica {
  background:  url('./assets/img/customer-logo-ica.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 14vw;
  height: 14vw;
}

.customer-logo-globalconnect {
  background:  url('./assets/img/customer-logo-globalconnect.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 35vw;
  height: 14vw;
}

.customer-logo-ncc {
  background:  url('./assets/img/customer-logo-ncc.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 20vw;
  height: 14vw;
}


.customer-cases  {
  margin: 5vh 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.customer-case {
  background: #fff;
  transition: background 0.2s linear;
  margin: 0 -5vw 0 -5vw;
  padding: 2vh 10vw 2vh 10vw;
  white-space: pre-wrap;
  display: flex;
  background: #fff;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-top: 1px #F4F2EF solid;
}

.customer-case:nth-last-child(1) {
  border-bottom: 1px #F4F2EF solid;
}

.customer-case:hover {
  background: linear-gradient(150deg, rgb(255,240,253) 0%, rgb(244,231,255) 100%);
  cursor: pointer;
}

.customer-case.show {
  background: linear-gradient(150deg, rgb(255,240,253) 0%, rgb(244,231,255) 100%);
  transition: width 0.5s ease-out, background 0.5s ease-in, padding 0s ease-out;
  overflow: hidden;
}

.customer-case.hide {
  transition: width 0.5s ease-out, background 0.5s ease-out;
}

.customer-case .title-text {
  font-family: Rubik-Medium;
  display: block;
  overflow: hidden;
  padding: 2vh 0 2vh 0;
  text-align: center;
  text-decoration: none;
  font-size: 4vw;
  clear: both;
  width: 100%;
  cursor: pointer;
}

.customer-case .arrow {
  margin: 0 0 0 0;
}
.customer-case.show .arrow {
  transition: opacity 0.25s ease-in 0s, transform 0.25s ease-out;
  opacity: 1; 
  justify-self: flex-end;
  transform: rotateZ(90deg);
}
.customer-case.hide .arrow {
  transition: opacity 0.2s ease-out 0.35s, transform 0.25s ease-out 0.3s;
  opacity: 1;
}

.customer-case .body-text-container {
  overflow: hidden;
  transition: height 0.2s ease-in;
  padding: 0;
  font-size: 4vw;
}

.customer-case .body-text-container p {
  padding: 0 0 2vh 0px;
  margin: 0;
}

.customer-case .body-text {
  margin: 2vh 0 0 0;
  overflow: hidden;
}

.customer-case.hide .body-text-container {
  overflow: hidden;
  transition: padding 0s linear;
  font-size: 4vw;
  padding: 0 5vw 0 5vw;
  width: 60vw;
  height: 0;
}

.section-container {
  min-height: 90vh;
  padding: 0 5vw 0 5vw;
  transform: translateZ(0);
}

.kundcase-content {
  padding-top:  5vh;
  display:  flex;
  flex-direction:  column;
  justify-content: space-between;
  min-height: calc(100vh - 5vh);
}
#kundcase {
  margin: 0 0vw;
  padding:  0 5vw;
  background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(250,249,247,1) 50%, rgba(255,255,255,0) 100%);
}
.kundcase-content .column p {
  text-align: center;
}

.section-container p {
  margin: 0 0 2vh 0;
  font-size: 4vw;
}

.section-title {
  text-align: center;
  margin: 0 0 7vh 0;
  overflow: hidden;
} 

.chapter-number {
  clear: both;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 6vw;
  height: 6vw;
  border: 1px solid #000;
  border-radius: 100%;
  font-family: Rubik-Light;
  font-size: 4vw;
  margin: 0 auto 4vh auto;
}

.section-title .small-text {
  clear: both;
  display: block;
  width: 100%;
  font-size: 3.5vw;
} 
.section-title .large-text {
  clear: both;
  display: block;
  width: 100%;
  font-family: "Rubik-Medium";
  text-transform: uppercase; 
  font-size: 10vw;
  line-height: 1em;
} 

.section-image {
  width: 100%;
  margin: 2.5vh 0 5vh 0;
}

#modiga-foretag .icon, #analytiska-formagan .icon {
  width: 20%;
  max-width: 200px;
}

.one-column, .two-column, .three-column {
  margin: 0 0 2.5vh 0;
}
.one-column {
  text-align: center;
  overflow: hidden;
  clear: both;
}
.one-column p {
  margin: 0 0 2.5vh 0;
}
.one-column .column {
  overflow: hidden;
  clear: both;
  vertical-align: top;
  text-align: left;
}

.two-column {
  text-align: center;
  overflow: hidden;
  clear: both;
}
.two-column .column {
  overflow: hidden;
  clear: both;
  vertical-align: top;
  text-align: left;
  margin: 0 0 0 0;
}
.two-column .row {
  overflow: hidden;
  margin: 0 0 0 0;
}
.two-column .column .right {
  max-width: 75%;
  float: left;
  margin-left: 5%;
  margin-bottom: 5vh;
}

.three-column {
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.three-column .column {
  overflow: hidden;
  clear: both;
  margin: 0 0 5vh 0;
  display:  flex;
  flex-direction: row;
  align-items: center;
}
.three-column .column:nth-last-child(1) {
  margin: 0 0 0 0;
}
.three-column .icon, .two-column .icon {
  float: left;
  clear: both;
  margin: 0 0 0 0;
}
.three-column p {
  float: left;
  width: 75%;
  margin: 0 0 0 5%;
  text-align: left;
}

.mask {
  fill: none;
  stroke: white;
  stroke-width: 2px;
}

.story-line-container {
  clear: both;
  overflow: hidden;
  text-align: center;
  width: 100%;
}

.story-line {
  width: 100%;
  margin: 0 auto 0 auto;
}
.story-line path {
  stroke-width: 0.3vw;
}

.group-photo {
  width: 100%;
  height: 40vh;
  margin: 2.5vh 0 5vh 0;
  background-image: url("./assets/img/kayenta-group.jpg");
  background-size: 100%; 
  background-position:  10% 10%;
  background-repeat:no-repeat;

}

.profile-container {
  text-align: center;
  vertical-align: top;
  overflow: hidden;
  width:  100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 10vh 0 0vh 0;
}

.profile {
  vertical-align: top;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap:  nowrap;
  align-items: center;
  width: 40vw;
  margin: 0 0 3vh 0;
  text-align: center;
}
/*.profile:nth-child(odd) {
  margin-right: 5vw;
}
.profile:nth-last-child(1),
.profile:nth-last-child(3) {
  margin-right: 0;
}*/

.profile-icons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  overflow: hidden;
}


.profile .icon {
  width: 10vw;
  max-width: 60px;
  margin: 0 0.5vw 0 0.5vw;
}
.profile .icon .circle {
  stroke: #231F20;
  fill: none;
}
.profile .icon .content {
  fill: #231F20;
}
.profile .icon.icon-phone:hover .circle {
  stroke: #9AEBCF;
  fill: #9AEBCF;
}
.profile .icon.icon-mail:hover .circle {
  stroke: #E4C3FF;
  fill: #E4C3FF;
}
.profile .icon.icon-linkedin:hover .circle {
  stroke: #B5CFFF;
  fill: #B5CFFF;
}
.profile .icon:hover .content {
  fill: #fff;
}

.profile-image {
  height: 40vw;
  width: 40vw;
}

.dummy-profile-image {
  height: 40vw;
  width: 40vw;
  background: rgb(244,242,239);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vw;
  font-family: "Rubik-Medium";
}

.profile-name {
  font-family: Rubik-Medium;
  font-size: 4vw;
  margin: 2.5vh 0 2.5vh 0;
}

.profile-tooltip {
  font-size: 4vw;
  width: 80vw;
  margin-top: 1vw;
}

.profile:nth-child(even) .profile-tooltip {
  margin: 1vh 0 1vh -43vw;
  text-align: right;
}

.profile:nth-child(odd) .profile-tooltip {
  margin: 1vh 0 1vh 43vw;
  text-align: left;
}

#fortsatta-resan {
  padding:  0 5vw 0 5vw;
}

.footer {
  overflow: hidden;
  margin: 0 0 20px 0;
  padding:  0 5vw 0 5vw;
  text-align: center;
}
.footer div {
  display: inline-block;
}

.buttons {
  display:  flex;
  justify-content: center;
  width: 100%;
  margin: 0 0 5vh 0;
}

.buttons a {
  text-decoration: none;
}

.button {
  display: flex;
  flex-direction:  column;
  justify-content: center;
  align-items:  center;
  overflow: hidden;
  padding: 4vh 0 4vh 0;
  border-radius: 5px;
  width: 40vw;
  max-width: 300px;
  color: #fff;
  background: #231F20;
}
.button:hover {
  transition: background 0.1s ease-in;
  background: #E4C3FF;
}
.buttons a:nth-child(odd) {
  margin-right: 5vw;
}
.button a {
  display: block;
  overflow: hidden;
  padding: 0;
}
.button .small-text {
  clear: both;
  display: block;
  font-size: 3.5vw;
} 
.button .large-text {
  clear: both;
  display: block;
  font-family: "Rubik-Medium";
  text-transform: uppercase; 
  font-size: 5vw;
  line-height: 1em;
} 
.footer-text {
  line-height: 2em;
}
.footer-navigation {
  clear: both;
  width: 100%;
  line-height: 2em;
}

.footer a:hover {
  color: #E4C3FF;
}

.arrow {
  display: block;
  margin: 0 20px 0 0;
  width: 35px;
  height: 35px;
}
.arrow svg {
  stroke: #231F20;
}

.arrow.left svg {
  transform: rotate(0deg);
}
.arrow.right svg {
  transform: rotate(180deg); 
  transition: all 0.2s ease-in;
}

.arrow.down svg {
  transition: all 0.2s ease-in;
  transform: rotate(270deg); 
}

.ie-svg-scale-fix {
  display: inline-block;
  width: 100%;
  position: relative;
  user-select: none;
}
.ie-svg-scale-fix canvas {
  display: block;
  width: 100%;
  height: 100% !important;
  visibility: hidden;
}
.ie-svg-scale-fix svg {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

#cookie-banner {
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  background: rgba(244,242,239,0.9);
}

#cookie-banner.hide {
  display: none;
}

.cookie-banner-content {
  margin: 7px auto 7px auto;
  padding: 0 5vw 0 5vw;
  font-size: 14px;
}
.cookie-banner-accept-button {
  cursor: pointer;
  clear: both;
  margin: 7px auto 7px auto;
  width: 33%;
  padding: 5px;
  color: #fff;
  background: #231F20;
  border-radius: 3px;
}
.cookie-banner-accept-button:hover {
  transition: background 0.1s ease-in;
  background: #9AEBCF;
}

#privacy-policy table {
  border-collapse: collapse;
  border: none;
  text-align: left;
  width: 100%;

}
#privacy-policy th {
  font-family: 'Rubik-Medium';
  font-size: 0.9em;
  border-bottom: 2px solid #F4F2EF;
  padding: 5px 10px 5px 10px;
}
#privacy-policy td {
  border-bottom: 1px solid #F4F2EF;
  padding: 5px 10px 5px 10px;
  vertical-align: top;
  word-wrap: break-word;
}



.progressbar {
  width: 20px;
  height: 100px;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  z-index: 100;
  top: calc(50% - 50px);
  align-items: center;
  justify-content: center;
  padding: 0 0 0 calc(5vw / 2 - 10px);
  transition: margin 0.4s ease-in;
}

.progressbar.hide {
  margin-left: calc(-5vw / 2 - 20px);
}

.progressbar .chapter {
  position: relative;
  cursor: pointer;
}

.progressbar .chapter .bullet {
  border: 1px solid #222020;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  transition: background 0.4s ease-in, border 0.4s ease-in;
}

.progressbar .chapter.current .bullet {
  background: #222020;
}

.progressbar .chapter.future .bullet {
  border-color: #E0DAD4;
}

.progressbar .chapter .label {
  display: none;
  position: absolute;
  top: -10px;
  left: 0px;
  height: 28px;
  padding: 0 10px 0 19px;
  white-space: nowrap;
  align-items: center;
  font-family: Rubik-Light;
  color: #222020;
  text-transform: uppercase;
  z-index: -1;
  background: #fff;
}

.progressbar .chapter:hover .label {
  color: #222020 !important;
  font-family: Rubik-Medium;
}
.progressbar .chapter.current .label  {
  color: #222020;
  font-family: Rubik-Medium;
}
.progressbar .chapter.future .label  {
  color: #B8ADAB;
}

.progressbar .path {
  background:  #222020;
  width: 1px;
  height: 20px;
  transition: background 0.4s ease-in;
}

.progressbar .path.future {
  background: #E0DAD4;
}

.progressbar:hover .chapter .label {
  display: flex;
}


/* Landscape aspect ratio  */
@media (min-aspect-ratio: 1/1) {
  #root {
    padding: 10vh 0 0 0;
  }

  h1 {
  font-family: Rubik-Bold;
  font-size: 4vw;
  }
  h2 {
    font-family: Rubik-medium;
    font-size: 1.5vw;
    margin: 2.5vh 0 1vh 0;
  }
  h3 {
    font-size: 3vw;
  }

  .header {   
    height: 8vh;
    min-height: 40px;
    background: none;
  }
  .header-logo {
    width: 15vh;
  }
  .header.header-hide {
    transform: translateY(-10vh)
  }

  .header .burger-container {
    margin: 0 2vw 0 0;
  }

  #nav-menu .nav-menu-items a {
    padding: 4vh 0 4vh 15vw;
  }
  #nav-menu .nav-menu-items a .large-text {
    font-size: 6.5vh;
  }
  #nav-menu .nav-menu-items a .small-text {
    font-size: 3vh;
  }
  #nav-menu .nav-menu-footer a {
    font-size: 3vh;
  }

  .start-page-content {
    display:  flex;
    flex-direction:  column;
    align-items: center;
    justify-content: space-between;
    height: calc(100vh - 10vh);
  }
  .kundcase-content {
    padding-top:  15vh;
    display:  flex;
    flex-direction:  column;
    justify-content: space-between;
    min-height: calc(100vh - 15vh);
  }

  #start-page-text {
    margin-top: 18vh;
    top: 0vh;
    font-size: 8vh;
    width: 90vw;
  }

  #start-page-background-img-1 {
    bottom:  -10vw;
    left:  -40vw;
    width:  80vw;
  }
  #start-page-background-img-2 {
    top:  -14vw;
    right:  -53vw;
    width:  80vw;
  }

  .scroll-down-button {
     margin-bottom: 5vh;
     font-size: 3vh;
    }
  .scroll-down-button .arrow {
   margin: 2vh 0 0 0;
   width: 5vh;
   height: 5vh;
  }


  #customer-case-summaries {
    border: none;
    margin: 5vh 0 5vh 0;
    width: 90vw;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: stretch;
  } 

  .customer-case-summary {
    border: 1px #F4F2EF solid;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    transition: background 0.2s linear;
    margin: 0 1vw;
    padding: 5vh 3vw 5vh 3vw;
  }

  .customer-case-summary.hide {
    flex: 1 1 auto;

  }
  .customer-case-summary.show {
    flex: 0 0 auto;
    max-width: 40vw;
  }
  .customer-case-summary .title-text {
    font-size: 3vh;
    font-family: 'Rubik-Medium';
    margin: 2vh 0 2vh 0;
    border: none;
    display:  flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
  }
  .customer-case-summary .title-text span {
    margin:  0;
  }

  .customer-case-summary.hide .title-text span {
    text-align: center;
  }

  .customer-case-summary .title-text .title-text-arrow {
    order:  2;
    margin:  4vh 0 0 0;
    display: flex;
    justify-content: center;
  }
  .customer-case-summary.show .title-text-arrow {
    display:  none;
  }

  .customer-case-summary .body-text-container p {
    padding: 0 0 2vh 0;
    margin: 0;
  }

  .customer-logo-ica {
    width: 7vh;
    height: 7vh;
  }

  .customer-logo-globalconnect {
    width: 22vh;
    height: 7vh;
  }

  .customer-logo-ncc {
    width: 11vh;
    height: 7vh;
  }

  .customer-cases  {
    margin: 10vh 0 5vh 0;
    width: 90vw;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: flex-start;
  }

  .customer-case {
    border:  0px solid #F4F2EF;
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
    border: none !important;
    transition: background 0.2s linear;
    margin: 0 1vw;
    padding: 5vh 3vw 5vh 3vw;
  }

  .customer-case.hide {
    flex: 1 1 auto;

  }
  .customer-case.show {
    flex: 0 0 auto;
    max-width: 40vw;
  }

  .customer-case .arrow {
    margin: 2vh auto 2vh auto;
    width: 5vh;
    height: 5vh;
  }
  .customer-case.show .arrow {
    display:  none;
  }

  .customer-case .body-text-container {
    margin: 4vh 0 0 0;
  }

  .customer-case .body-text-container p {
    padding: 0 0 2vh 0;
  }

  .customer-case.hide .body-text-container {
    height:  0;
    margin: 0 0 0 0;
  }

  .customer-case .body-text {
    margin:  0;
  }

  .section-container {
    padding-top: 0;
  }

  .section-container:nth-child(2) {
    padding-top: 0;
  }
  
  .section-container p {
    font-size: 3vh;
    float: left;
    max-width: 40vw;
    clear: left;
    margin: 1vh 0 1vh 0;
  }
  .section-title {
    text-align: center;
  } 

  .chapter-number {
    width: 5vh;
    height: 5vh;
    font-size: 3vh;
    margin:  0 auto 8vh auto;
  }

  .section-title .small-text {
    font-size: 2vw;
  } 
  .section-title .large-text {
    font-size: 7vw;
  } 

  .section-image {
    max-width: 60vw;
    margin: 5vh 0 5vh 0;
  }

  #modiga-foretag .icon {
    width: 15vw;
    max-width: 200px;
  }

  .one-column {
    text-align: center;
    overflow: hidden;
    clear: both;
    
  }
  .one-column .column {
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  .one-column p {
    width: 100%;
    max-width: 100%;
  }

  .two-column {
    text-align: center;
    display: flex;
    flex-direction: row;
    
  }
  .two-column .column {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 34vw;
    margin: 2.5vw 5vw 0 5vw;
  }
  .two-column .row {
    margin-top: 2.5vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .two-column .column .right {
    margin-left: 5%;
    margin-top: 0;
  }

  .two-column p {
    width: 100%;
    max-width: 100%;
  }

  .three-column {
    text-align: center;
    flex-direction: row;
    justify-content: space-between;
  }
  .three-column .column, .three-column .column:nth-last-child(1) {
    flex-direction: column;
    width: 24vw;
    margin: 2.5vw 2.5vw 0 2.5vw;
  }
  .three-column .icon {
    float: none;
    clear: both;
    margin: 0 0 2vh 0;
  }
  .three-column p {
    float: none;
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .story-line {
    width: 75%;
  }
  .story-line path {
    stroke-width: 0.1vh;
  }

  .group-photo {
    margin: 2.5vh 0 5vh 0;
    height: 60vh;
    background-position: 0 30%;
    background-size: 100%; 
  }


  .profile-container {
    padding: 10vh 0 10vh 0;
    justify-content: center;
  }


  .profile {
    
    width: 22vw;
  }
  /*.profile:nth-child(3), .profile:nth-child(5) {
    margin-right: 0 !important;
  }*/

  .profile-image {
    height: 18vw;
    width: 18vw;
  }

  .dummy-profile-image {
    height: 18vw;
    width: 18vw;
    font-size: 2vw;
  }

  .profile-name, .profile-tooltip {
    font-size: 2vw;
  }

  .profile .profile-tooltip {
    width: 18vw;
    margin: 1vh 0 1vh 0 !important;
    text-align: center !important;
  }

  .profile .icon {
    width: 4vw;
    margin: 0 0.5vw 0 0.5vw;
  }

  .footer-navigation {
    float: right;
    clear: none;
    width: inherit;
    line-height: 2em;
  }

  .footer-content {
    margin: 0 0 5vh 0;
  }

  .button .small-text {
    font-size: 26px;
  } 
  .button .large-text {
    font-size: 40px;
  }

  .progressbar {
    width: 20px;
    padding: 0 0 0 20px;
  }

  .progressbar.hide {
    margin-left: -40px;
  }

}

/* For very wide screens */
@media (min-width: 1000px) {
  .header .burger-container #burger .bar {
    height: 2px;
    border-radius: 1px;
  }
  .header.show .burger-container #burger .bar.bottomBar {
    transform: translateY(1px) rotate(-45deg);
  }
  .profile-name, .profile-tooltip {
    font-size: 1.5vw;
  }
}
/* For very wide screens */
@media (min-width: 1900px) {

  .group-photo {
    background-position: 0 0%;
  }
}
